import React from "react"

function Pagination({ currentPage, setCurrentPage }) {
  return (
    <div className=" col-12 m-auto pt-5" style={{ fontSize: "18px" }}>
      <ul className="pagination">
        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
          <button onClick={() => setCurrentPage(1)} className="page-link">
            Первая
          </button>
        </li>
        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
          <button
            onClick={() => {
              if (currentPage > 1) setCurrentPage(currentPage - 1)
            }}
            className="page-link"
          >
            «
          </button>
        </li>
        <li className={`page-item ${currentPage === 1 ? "active" : ""}`}>
          <button onClick={() => setCurrentPage(1)} className="page-link">
            1
          </button>
        </li>
        <li className={`page-item ${currentPage === 2 ? "active" : ""}`}>
          <button onClick={() => setCurrentPage(2)} className="page-link">
            2
          </button>
        </li>
        <li className={`page-item ${currentPage === 3 ? "active" : ""}`}>
          <button onClick={() => setCurrentPage(3)} className="page-link">
            3
          </button>
        </li>
        <li className={`page-item ${currentPage === 4 ? "active" : ""}`}>
          <button onClick={() => setCurrentPage(4)} className="page-link">
            4
          </button>
        </li>
        <li className={`page-item ${currentPage === 4 ? "disabled" : ""}`}>
          <button
            onClick={() => {
              if (currentPage < 4) setCurrentPage(currentPage + 1)
            }}
            className="page-link"
          >
            »
          </button>
        </li>
        <li className={`page-item ${currentPage === 4 ? "disabled" : ""}`}>
          <button onClick={() => setCurrentPage(4)} className="page-link">
            Последняя
          </button>
        </li>
      </ul>
    </div>
  )
}

export default Pagination
