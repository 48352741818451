import React, { useEffect, useState } from "react"

import Layout from "../../components/Layout"
import HeadSection from "../../components/HeadSection"
import Header from "../../components/Home/Header"
import CatalogCeramic from "../../components/CatalogCeramic"
import AdvantagesSection from "../../components/Home/AdvantagesSection"
import OfferSection from "../../components/Home/OfferSection"

import {
  ceramicCars,
  ceramicCars2,
  ceramicCars3,
  ceramicCars4,
} from "../../components/CatalogCeramic/data"
import Pagination from "../../components/CatalogCeramic/Pagination"

const CatalogCeramicPage = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [data, setData] = useState(ceramicCars)

  useEffect(() => {
    switch (currentPage) {
      case 1:
        setData(ceramicCars)
        break
      case 2:
        setData(ceramicCars2)
        break
      case 3:
        setData(ceramicCars3)
        break
      case 4:
        setData(ceramicCars4)
        break
      default:
        console.log("Неправильный номер страницы")
    }
    window.scrollTo(0, 50)
  }, [currentPage])

  return (
    <>
      <HeadSection
        title="Цены на керамические катализаторы"
        description="Каталог керамических катализаторов, которые мы выкупили у частных клиентов. Цены на них и данные по автомобилю, являющегося донором катализатора."
      />
      <Layout>
        <div className="screen">
          <Header />
        </div>
        <CatalogCeramic data={data}>
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </CatalogCeramic>
        <AdvantagesSection />
        <OfferSection />
      </Layout>
    </>
  )
}

export default CatalogCeramicPage
