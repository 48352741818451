import React from "react"

import PriceCard from "./PriceCard"
import PriceActions from "../Home/PriceActions"

function CatalogCeramic({ data, children }) {
  return (
    <>
      <section className="price" style={{ paddingTop: "70px" }}>
        <div className="container" style={{ fontSize: "16px" }}>
          <a href="/" style={{ color: "#ff8a2c" }}>
            Главная
          </a>{" "}
          / Керамические катализаторы
          <h1 className="section-title section-title--extra-bold price-title text-left mt-3 mt-md-5">
            Керамические катализаторы{" "}
          </h1>
          <h2 className="price-subtitle text-left">
            Цены на Керамические катализаторы
          </h2>
          <div id="pdopage" className=" rows price-cards">
            {data.map((car, index) => (
              <PriceCard
                key={`price-card-${index}`}
                link={car.link}
                name={car.name}
                year={car.year}
                img={car.img}
                price={car.price}
                date={car.date}
              />
            ))}
            {children}
          </div>
          <PriceActions />
        </div>
      </section>
    </>
  )
}

export default CatalogCeramic
